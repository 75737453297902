import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "object" ]


  focusin() {
    // focus action
    $(this.objectTargets).show()
  }

  focusout() {
    // focusout action
    // $(this.objectTargets).hide()
  }

}