/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// console.log('Hello World from Webpacker')

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")
// require("trix")
require("@rails/actiontext")
//

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import flatpickr from "flatpickr"
global.$ = require("jquery");

require("jquery")
require("jquery-ui")
// require("jquery-ui")
import bootstrap from 'bootstrap'
import material from 'material-design-lite'
// require("material")
// import bootstrap from 'bootstrap'
import "controllers"


// , require("jquery-ui")
// require("jquery-ui")

document.addEventListener('turbolinks:before-cache', () => {
  // $('.select2').select2('destroy');  
});

document.addEventListener('turbolinks:load', () => {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })

  $(function () {
    $('[data-toggle="popover"]').popover()
  })

  $('.toggle').click(function(){
    $('.toggle-body').toggle();
  })

  
  $('.hide-drawer').click(function() {
    $('.mdl-layout').toggleClass('mdl-layout--fixed-drawer')
  })
})

