import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "template", "group", "question", "label", "container" ]


  initialize() {
  }

  connect() {
    // console.log(this.questionTargets)
    // Set department
    // var customer_id = this.templateTarget.value
    // var all_groups = this.groupTargets
    // var all_questions = this.questionTargets
    // var all_labels = this.labelTargets
    // $(all_labels).hide()
    // $(all_questions).hide()
    // $(all_groups).hide()

    // var question_groups = $('*[data-template-id="' + customer_id + '"]')

    // $(customer_departments).show()
    // $(customer_departments).siblings().show()
  }

  update() {
    var template_id = this.templateTarget.value
    var all_departments = this.departmentTargets
    var all_labels = this.labelTargets
    $(all_labels).hide()
    $(all_departments).hide()
    $(all_departments).prop('checked', false)

    var customer_departments = $('*[data-customer-id="' + customer_id + '"]')
    // $(customer_departments).show()
    // $(customer_departments).siblings().show()
  }


  hideDepartments(){
    var customer_id = this.customerTarget.value
    var all_departments = this.departmentTargets
    var all_labels = this.labelTargets
    $(all_labels).hide()
    $(all_departments).hide()
  }


  update_from_template() {
    var $this = this
    $(this.questionTargets).prop('checked', false)
    $(this.groupTargets).prop('checked', false)
    var account_id = $('.account').data('account')
    var scorecard_template_id = $('#scorecard_set_scorecard_template_id').val()

    if(scorecard_template_id == "") {
      var scorecard_template_id = "new"
    }

    var request = $.ajax({
                    url: "/" + account_id + "/scorecard_templates/" + scorecard_template_id,
                    dataType: "json"
                  })

    request.done(function(params) {
      var question_ids = params.question_ids

      $($this.questionTargets).each(function(){
        if( question_ids.includes( parseInt($(this).val()))) {
          $(this).prop('checked', true)
        }
      })

      if( !$(question_ids).length ) {
        $($this.questionTargets).prop('checked', true)
      }

          // console.log('hey')
      var checked = $($this.questionTargets).filter(':checked')

      var groups = $($this.containerTargets)

      groups.each(function(){
        var checkboxes = $(this).find('input')
        var length = checkboxes.length
        var checked_checkboxes_length = $(this).find('input:checked').length
        if (length == checked_checkboxes_length) {
          $(this).closest('.group-check-div').find('.group-check').prop('checked', true)
        }
      })
    })
  }

}