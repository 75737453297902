import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "template" ]


  openModal() {
    var id = $(this.templateTarget).data('id')
    $('#scorecard_set_v2_template_id').val(id)
    $('#new_scorecard_modal').modal('toggle')
  }
}