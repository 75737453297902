import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "toggle", "body", "arrow" ]

  toggle() {
    var body = this.bodyTarget
    var arrow = this.arrowTarget
    var arrow_direction = $(arrow).data('direction')

    $(body).toggle()
    if ( arrow_direction === 'up') {
      $(arrow).text("keyboard_arrow_down")
      $(arrow).data('direction', 'down')
    } else {
      $(arrow).text('keyboard_arrow_up')
      $(arrow).data('direction', 'up')

    }
  }

}