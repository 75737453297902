import { Controller } from "stimulus"
import selectize from 'selectize'
var $ = require("jquery");


export default class extends Controller {
  static targets = [ "answer", "question", "question_v2", "error", "comment" ]


  connect() {
    // var account_id = $(this.question_v2Target).data('account-id')

    // console.log(event.target)
    // console.log(account_id)
    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

  }


  setYes(){
    var $this = this
    var result = 'ja'
    var new_record = $(this.question_v2Target).data('new')

    if (new_record == true) {
      $this.create(result)
    } else {
      $this.update(result)
    }
  }

  setNo() {
    var $this = this
    var result = 'nei'
    var new_record = $(this.question_v2Target).data('new')

    if (new_record == true) {
      $this.create(result)
    } else {
      $this.update(result)
    }
  }

  setNA() {
    var $this = this
    var result = 'na'
    var new_record = $(this.question_v2Target).data('new')

    if (new_record == true) {
      $this.create(result)
    } else {
      $this.update(result)
    }
  }

  create(result) {
    // var comment = this.commentTarget.value
    var account_id = $(this.question_v2Target).data('account-id')
    var scorecard_set_v2_id = $(this.question_v2Target).data('scorecard-set-id')
    var scorecard_v2_id = $(this.question_v2Target).data('scorecard-id')
    var question_v2_id = $(this.question_v2Target).data('question-id')
    var answer_id = $(this.question_v2Target).data('answer-id')

    $.ajax ({
      url: `/${account_id}/answer_v2s/`,
      data: {
        '[answer_v2]question_v2_id': question_v2_id,
        '[answer_v2]scorecard_set_v2_id': scorecard_set_v2_id,
        '[answer_v2]scorecard_v2_id': scorecard_v2_id,
        '[answer_v2]account_id': account_id,
        '[answer_v2]result': result,
      },
      method: "POST",
      dataType: 'script'
    })
  }

  update(result) {
    // var comment = this.commentTarget.value
    var account_id = $(this.question_v2Target).data('account-id')
    var scorecard_set_v2_id = $(this.question_v2Target).data('scorecard-set-id')
    var scorecard_v2_id = $(this.question_v2Target).data('scorecard-id')
    var question_v2_id = $(this.question_v2Target).data('question-id')
    var answer_id = $(this.question_v2Target).data('answer-id')
    var new_record = $(this.question_v2Target).data('new')

    $.ajax ({
      url: `/${account_id}/answer_v2s/${answer_id}`,
      data: {
        '[answer_v2]question_v2_id': question_v2_id,
        '[answer_v2]scorecard_set_v2_id': scorecard_set_v2_id,
        '[answer_v2]scorecard_v2_id': scorecard_v2_id,
        '[answer_v2]result': result,
      },
      method: "PATCH",
      dataType: 'script'
    })
  }


  setComment(){
    var $this = this
    var new_record = $(this.question_v2Target).data('new')

    if (new_record == true) {
      $this.createComment()
    } else {
      $this.updateComment()
    }
  }

  createComment() {
    // var comment = this.commentTarget.value
    var account_id = $(this.question_v2Target).data('account-id')
    var scorecard_set_v2_id = $(this.question_v2Target).data('scorecard-set-id')
    var scorecard_v2_id = $(this.question_v2Target).data('scorecard-id')
    var question_v2_id = $(this.question_v2Target).data('question-id')
    var answer_id = $(this.question_v2Target).data('answer-id')

    $.ajax ({
      url: `/${account_id}/answer_v2s/`,
      data: {
        '[answer_v2]question_v2_id': question_v2_id,
        '[answer_v2]scorecard_set_v2_id': scorecard_set_v2_id,
        '[answer_v2]scorecard_v2_id': scorecard_v2_id,
        '[answer_v2]comment': comment,
        '[answer_v2]account_id': account_id
      },
      method: "POST",
      dataType: 'script'
    })
  }

  updateComment(comment) {
    var comment = this.commentTarget.value
    var account_id = $(this.question_v2Target).data('account-id')
    var scorecard_set_v2_id = $(this.question_v2Target).data('scorecard-set-id')
    var scorecard_v2_id = $(this.question_v2Target).data('scorecard-id')
    var question_v2_id = $(this.question_v2Target).data('question-id')
    var answer_id = $(this.question_v2Target).data('answer-id')

    $.ajax ({
      url: `/${account_id}/answer_v2s/${answer_id}`,
      data: {
        '[answer_v2]question_v2_id': question_v2_id,
        '[answer_v2]scorecard_set_v2_id': scorecard_set_v2_id,
        '[answer_v2]scorecard_v2_id': scorecard_v2_id,
        '[answer_v2]comment': comment
      },
      method: "PATCH",
      dataType: 'script'
    })
  }


  showLog() {
    var account_id = $(this.question_v2Target).data('account-id')
    var answer_id = $(this.question_v2Target).data('answer-id')
    var question_v2_id = $(this.question_v2Target).data('question-id')

    $.ajax ({
      url: `/${account_id}/answer_v2s/log_modal`,
      method: "GET",
      dataType: 'script',
      data: {
        'question_v2_id': question_v2_id,
        'answer_v2_id': answer_id
      }
    })
  }


  onPostSuccess(event) {
    console.log(event)
    let [data, status, xhr] = event.detail;
    console.log(data)
    console.log(xhr.response)
    // $('#question_v2_' + data.question_v2_id).replaceWith(data.html)
    // this.answerTarget.innerHTML += xhr.response;
    if ($('input[name="_method"]').length != 0){
      $(this.answerTarget).append('<input type="hidden" name="_method" value="patch">')
    }
  }

  onPostError(event) {
    let [data, status, xhr] = event.detail;
    // this.errorTarget.innerHTML = xhr.response;
  }
}