
import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    function update_new_modal() {
      var customer_name = $('#scorecard_set_customer_id option:selected').text();
      var scorecard_name = $('#scorecard_set_name').val()
      $('#name').val( customer_name + " - " + scorecard_name )

      var customer_id = $('#scorecard_set_customer_id option:selected').val();

      $(".question_customer").val(customer_id)
    }

    $('#scorecard_set_customer_id').change(function() {
      update_new_modal();
    })

    $('#scorecard_set_name').change(function() {
          update_new_modal();
    })

    update_new_modal()


    $('.question_name').keyup(function(event){
      var group = $(event.target).closest('.new-question').find('.group_id').val()
      $('.hidden_question_group').val(group)
      $('.hidden_question_name').val($(event.target).val())
    })

    $(".new_question_button").click(function(){
      Rails.fire($('#hidden_new_question_form')[0], 'submit')
    })


    $('.question_group_name').keyup(function(event){
      $('.hidden_question_group_name').val($(event.target).val())
    })

    $(".new_question_group_button").click(function(){
      Rails.fire($('#hidden_new_question_group_form')[0], 'submit')
    })
  } 
}