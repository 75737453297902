import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "customer", "department", "label" ]

  connect() {
    // Set department
    var customer_id = this.customerTarget.value
    var all_departments = this.departmentTargets
    var all_labels = this.labelTargets
    $(all_labels).hide()
    $(all_departments).hide()

    var customer_departments = $('*[data-customer-id="' + customer_id + '"]')
    $(customer_departments).show()
    $(customer_departments).siblings().show()
  }

  update() {
    console.log('updates')
    var customer_id = this.customerTarget.value
    var all_departments = this.departmentTargets
    var all_labels = this.labelTargets
    $(all_labels).hide()
    $(all_departments).hide()
    $(all_departments).prop('checked', false)

    var customer_departments = $('*[data-customer-id="' + customer_id + '"]')
    $(customer_departments).show()
    $(customer_departments).siblings().show()

    var account = $('.account').data('account')
    var name = $('#scorecard_set_name').val()
    var scorecard_template_id = $('#scorecard_set_scorecard_template_id').val()
    // var request = $.ajax({
    //   url: '/' + account + '/scorecard_sets/new?customer_id=' + customer_id,
    //   dataType: 'html'
    // });

    // request.success(function() {
      // location.reload();
      var url = window.location.href
      url = url.split('?')[0]

      window.location.href = url + "?customer_id=" + customer_id + "&name=" + name + "&scorecard_template_id=" + scorecard_template_id;
    // })
  }


  hideDepartments(){
    var customer_id = this.customerTarget.value
    var all_departments = this.departmentTargets
    var all_labels = this.labelTargets
    $(all_labels).hide()
    $(all_departments).hide()
  }
}