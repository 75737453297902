import { Controller } from "stimulus"
// var $ = require("jquery");

export default class extends Controller {
  static targets = [ "message", 'modal']

  connect() {
    $(this.modalTarget).modal('toggle')
  }
}
