import { Controller } from "stimulus"
import selectize from 'selectize'
var $ = require("jquery");


export default class extends Controller {
  static targets = [ "user", "scorecard_select", "scorecard_select_container" ]

  connect() {
    var $this = this

    if ($(this.userTarget).data('has-access') == false) {
      $($this.scorecard_select_containerTarget).hide();
    } 

    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });


    window.scorecardSelect = $(this.scorecard_selectTarget).selectize({
      plugins: ['remove_button'],
      onChange: function(value, item) {
        var account = $('.account').data('account');
        var user_id = $($this.userTarget).data('user-id');
        var scorecard_set_v2_id = $($this.userTarget).data('scorecard-set-id');
        var scorecard_v2_id = value

        $.ajax ({
          url: `/${account}/users/${user_id}/update_accesses`,
          dataType: 'script',
          method: "PATCH",
          data: {
            scorecard_set_v2_id: scorecard_set_v2_id,
            scorecard_v2_id: value
          }
        })
      }
    });

  }

  setScorecard() {
    $(this.scorecard_select_containerTarget).show()
    this.setNone()

  }

  setScorecardSet() {
    $(this.scorecard_select_containerTarget).hide()

    var account = $('.account').data('account');
    var user_id = $(this.userTarget).data('user-id');
    var scorecard_set_v2_id = $(this.userTarget).data('scorecard-set-id');
    $.ajax ({
      url: `/${account}/users/${user_id}/update_accesses`,
      dataType: 'script',
      method: "PATCH",
      data: {
        all: true,
        scorecard_set_v2_id: scorecard_set_v2_id
      }
    })
  }

  setNone() {
    var account = $('.account').data('account');
    var user_id = $(this.userTarget).data('user-id');
    var scorecard_set_v2_id = $(this.userTarget).data('scorecard-set-id');

    $.ajax ({
      url: `/${account}/users/${user_id}/update_accesses`,
      dataType: 'script',
      method: "PATCH",
      data: {
        scorecard_set_v2_id: scorecard_set_v2_id,
        none: true
      }
    })
  }



}