import { Controller } from "stimulus"
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = [ "question", "questions", "group" ]


  connect() {
    var $this = this
    var el = this.questionsTarget;
    var sortable = Sortable.create(el, {
      handle: ".handle",
      dragoverBubble: true,
      animation: 300,
      
      onEnd: function (/**Event*/evt) {
        var ids = $($this.questionTargets).map(function() {return $(this).data('id');}).get()
        $this.updatePosition(ids)
      },

    });
  }

  updatePosition(ids) {

    var $this = this
    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    var account = $('.account').data('account')
    var scorecard_set_v2_id = $('.scorecard_set_v2').data('id')
  
    $.ajax ({
      url: `/${account}/scorecard_set_v2s/${scorecard_set_v2_id}/question_v2s/sort`,
      data: {
        'ids': ids
      },
      method: "PATCH"
    })




  }

}