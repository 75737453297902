import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "yes", "no", "choose", "new" ]

  connect() {
    if($('#scorecard_set_name').val() == "" ) {
        var name = getUrlParameter('name');
        $('#scorecard_set_name').val(name)
    }

    function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };





    // console.log('hey')
    // // Set department
    // var customer_id = this.templateTarget.value
    // var all_groups = this.groupTargets
    // var all_questions = this.questionTargets
    // var all_labels = this.labelTargets
    // $(all_labels).hide()
    // $(all_questions).hide()
    // $(all_groups).hide()

    // var question_groups = $('*[data-template-id="' + customer_id + '"]')

    // $(customer_departments).show()
    // $(customer_departments).siblings().show()
  }

  template() {
    // console.log($(this.chooseTarget))
    $(this.chooseTarget).show()
    $(this.newTarget).hide()
    // var template_id = this.Target.value
    // var all_departments = this.departmentTargets
    // var all_labels = this.labelTargets
    // $(all_labels).hide()
    // $(all_departments).hide()
    // $(all_departments).prop('checked', false)

    // var customer_departments = $('*[data-customer-id="' + customer_id + '"]')
    // $(customer_departments).show()
    // $(customer_departments).siblings().show()

  }


  new(){
    $(this.chooseTarget).hide()
    $(this.newTarget).show()
  }

  update_questions() {
    
  }
}