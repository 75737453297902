import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "department"]

  connect() {
    var name = $("#scorecard_set_name").val()
    $(".scorecard_set_name").val(name)
  }

  changeName() {
    var name = $("#scorecard_set_name").val()
    $(".scorecard_set_name").val(name)
  }
  
  changeTemplateNew() {
    var account_id = $('.account').data('account')
    var scorecard_template_id = $('#scorecard_set_scorecard_template_id').val()
    var customer_id = $('#scorecard_set_customer_id').val()
    var name = $("#scorecard_set_name").val()

    // var customer_department_ids = $('.scorecard_set_customer_department_ids').val()
    var customer_department_ids = $(this.departmentTargets).filter(":checked").map(function() {
      return $(this).val();}).get()

    $.ajax({
      url: "/" + account_id + "/scorecard_sets/new",
      dataType: "script",
      data: {
        scorecard_template_id: scorecard_template_id,
        customer_id: customer_id,
        customer_department_ids: customer_department_ids,
        name: name
      }
    })
  }

  changeTemplateEdit() {
    var account_id = $('.account').data('account')
    var name = $("#scorecard_set_name").val()
    var scorecard_set_id = $('#scorecard_set_scorecard_set_id').val()
    var scorecard_template_id = $('#scorecard_set_scorecard_template_id').val()
    var customer_id = $('#scorecard_set_customer_id').val()
    var customer_department_ids = $(this.departmentTargets).filter(":checked").map(function() {
      return $(this).val();}).get()

    $.ajax({
      url: "/" + account_id + "/scorecard_sets/" + scorecard_set_id + '/edit/',
      dataType: "script",
      data: {
        scorecard_template_id: scorecard_template_id,
        customer_id: customer_id,
        customer_department_ids: customer_department_ids,
        name: name
      }
    })
  }
}