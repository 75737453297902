import { Controller } from "stimulus"
import List from 'list.js'

export default class extends Controller {
  static targets = [ "list" ]

  connect() {
    var options = {
      valueNames: [ {name: 'name', attr: 'data-name'}, {name: 'customer', attr: 'data-customer'}, {name: "date", attr: "data-date"} ]
    };

    var scorecard_set_list = new List('list', options);
  }

}