import { Controller } from "stimulus"
import List from 'list.js'

export default class extends Controller {
  static targets = [ "list" ]

  connect() {
    var options = {
      valueNames: [ {name: 'name', attr: 'data-name'}]
    };

    var scorecard_set_list = new List('list', options);
  }

}