import { Controller } from "stimulus"
// var $ = require("jquery");

export default class extends Controller {
  static targets = [ "all", "event", "department" ]

  connect() {
    $(this.eventTargets).filter(":checked").map(function() {
      $(this).closest('.list-group-item').addClass('list-group-item-info')
    })

    var array = $(this.departmentTargets).filter(":checked").map(function() {
      return $(this).val();}).get()
    var myHtml = "";

    $.each(array, function(index, value) {
      myHtml += "<input class='user_id' type='hidden' name='customer_department_ids[]' value=" + value + ">";
     })

    $(".ids").each(function(){
      $(this).html( myHtml )
    })


  }

  toggleAll() {
    if ($(this.allTarget).prop('checked') == false) {
      $(this.eventTargets).filter(":enabled").prop('checked', false)
      // $(this.allTarget).prop('checked', false)
      this.toggle()
    }
    if ($(this.allTarget).prop('checked') == true) {
      $(this.eventTargets).filter(":enabled").prop('checked', true)
      // $(this.allTarget).prop('checked', false)
      this.toggle()
    }
  }

  toggle() {

    var array = $(this.departmentTargets).filter(":checked").map(function() {
      return $(this).val();}).get()
    var myHtml = "";

    $.each(array, function(index, value) {
      myHtml += "<input class='user_id' type='hidden' name='customer_department_ids[]' value=" + value + ">";
     })

    $(".ids").each(function(){
      $(this).html( myHtml )
    })

    $(this.eventTargets).filter(":not(:checked)").map(function() {
      $(this).closest('.list-group-item').removeClass('list-group-item-info')
    })

    $(this.eventTargets).filter(":checked").map(function() {
      $(this).closest('.list-group-item').addClass('list-group-item-info')
    })

  }

  highlight() {
    $(this.eventTargets).filter(":checked").map(function() {
      $(this).closest('.list-group-item').addClass('list-group-item-info')
    })
  }

}
